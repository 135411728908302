<template>
    <div class="page" :loaded="Boolean(page) && Boolean(members)">
        <div v-if="page" class="page-background" :style="{backgroundImage: `url(${page.image})`}">
            <h1>Board<br>of Directors</h1>
            <!-- <p>{{page.subtitle}}</p> -->
        </div>
        <div class="page-title">
            <h2>Board of Directors</h2>
        </div>
        <!-- <div class="scroll-buttons">
            <button @click="scrollUp" ref="previousBtn" disabled><Icon icon="chevron-up" /></button>
            <button @click="scrollDown" ref="nextBtn"><Icon icon="chevron-down" /></button>
        </div> -->
        <div ref="team" class="team">
            <a class="member" v-for="member in members || []" :key="member.name" @click="selectedMember = member">
                <div class="member-photo-container">
                    <div class="member-photo" :style="{backgroundImage: `url(${member.image})`}"></div>
                    <div class="read-more">Read more</div>
                </div>
                <div class="member-name">{{member.name}}</div>
                <div class="member-role">{{member.role}}</div>
            </a>
        </div>
        <div class="overlay" :opened="Boolean(selectedMember)" @click="selectedMember = null">
            <div class="modal" v-if="selectedMember" @click.stop>
                <div class="modal-actions">
                    <a class="ic ic-close-b" @click="selectedMember = null" />
                </div>
                <div class="modal-content">
                    <div class="director-card">
                        <div class="director-details">
                            <div class="director-image">
                                <img :src="selectedMember.image" :alt="selectedMember.name">
                            </div>
                            <div>
                                <div class="director-name">{{selectedMember.name}}</div>
                                <div class="director-role">{{selectedMember.role}}</div>
                            </div>
                        </div>
                        <div class="director-bio" v-html="selectedMember.bio" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Icon from './Icon.vue'

export default {
    name: "Directors",
    components: {
        Icon
    },
    data() {
        return {
            members: null,
            page: null,
            selectedMember: null,
        }
    },
    computed: {
        db() {
            return this.$store.state.firebase.firestore
        },
        ...mapGetters({
            testMode: 'getTest'
        })
    },
    watch: {
        testMode() {
            this.getItems()
        }
    },
    methods: {
        scrollUp() {
            const y = this.scrollable.scrollTop
            const boxes = Array.from(this.$el.querySelectorAll('.member')).reverse()
            const index = boxes.findIndex(box => box.offsetTop < y)
            if(index > -1) {
                this.boxIndex = index
                const box = boxes[index]
                this.scrollToY(box.offsetTop, this.scrollable)
            }
            else {
                this.scrollToY(0, this.scrollable)
            }
        },
        scrollDown() {
            const y = this.scrollable.scrollTop
            const boxes = Array.from(this.$el.querySelectorAll('.member'))
            const index = boxes.findIndex(box => box.offsetTop > y)
            if(index > -1) {
                this.boxIndex = index
                const box = boxes[index]
                this.scrollToY(box.offsetTop, this.scrollable)
            }
        },
        toggleButtons() {
            const y = this.scrollable.scrollTop
            this.$refs.previousBtn.disabled = y === 0
            this.$refs.nextBtn.disabled = this.scrollable.scrollHeight === this.scrollable.scrollTop + this.scrollable.clientHeight
        },
        getItems() {
            if(this.pageRef) this.pageRef()
            if(this.teamRef) this.teamRef()
            this.pageRef = this.db().collection(`${this.testMode ? 'test_' : ''}pages`).doc('directors').onSnapshot(page => {
                this.page = page.exists ? page.data() : {subtitle: '', image: ''}
            })
            this.teamRef = this.db().collection(`${this.testMode ? 'test_' : ''}directors`).orderBy('order').onSnapshot(members => {
                this.members = members.docs.map(doc => doc.data())
            })
        }
    },
    mounted: function() {
        this.getItems()
        // this.scrollable = this.$refs.team
        // this.scrollable.addEventListener('scroll', this.toggleButtons)
    },
    beforeDestroy: function() {
        if(this.pageRef) this.pageRef()
        if(this.teamRef) this.teamRef()
    }
}
</script>

<style scoped>
.page {
    height: 100%;
    background: url(../assets/images/wb_team_background.png) no-repeat center;
    background-size: cover;
    opacity: 0;
    transition: opacity .16s ease-in;
}

.page[loaded] {
    opacity: 1;
}

.page-title {
    display: none;
}

.page-background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 37.96vh;
    margin: auto;
    background: url(../assets/images/wb_team_background.jpg) no-repeat top center/cover;
    z-index: 2;
    pointer-events: none;
}

.page-background:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(10,126,140);
    background: -moz-linear-gradient(0deg, rgba(10,126,140,1) 0%, rgba(31,89,96,0.2) 40%, rgba(52,52,52,0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(10,126,140,1) 0%, rgba(31,89,96,0.2) 40%, rgba(52,52,52,0) 100%);
    background: linear-gradient(0deg, rgba(10,126,140,1) 0%, rgba(31,89,96,0.2) 40%, rgba(52,52,52,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0a7e8c",endColorstr="#343434",GradientType=1);
    opacity: .69;
}

.page-background h1 {
    position: absolute;
    bottom: 1.85vh;
    left: 1.98vw;
    font: bold 7.96vh/.8 'Program';
    color: #fff;
    z-index: 1;
}

.page-background p {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.11vh;
    margin-top: 1.85vh;
    color: #656662;
}

.team {
    position: relative;
    height: 100%;
    padding: 43.88vh 3.12vw 0 3.12vw;
    overflow: auto;
}

.team:after {
    content: '';
    display: block;
    clear: both;
}

.team .member {
    float: left;
    width: 20%;
    margin-bottom: 8.33vh;
    text-align: center;
    color: #0A7E8C;
}

.team .member .member-photo-container,
.director-card .director-image {
    position: relative;
    display: inline-block;
    padding: 2.13vh 2.73vh 2.13vh 1.8vh;
    background: url(../assets/images/wb_team_bubbles.svg) no-repeat center right;
    background-size: contain;
    border-radius: 50%;
    margin-bottom: 1.66vh;
}

.team .member .member-photo-container .read-more {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 2vh;
    left: 1.7vh;
    right: 2.6vh;
    bottom: 2vh;
    background: rgba(255, 255, 255, 0.88);
    border-radius: 50%;
    opacity: 0;
    transition: opacity .2s ease-out;
}

.team .member:hover .member-photo-container .read-more {
    opacity: 1;
}

.team .member .member-photo {
    width: 11.11vh;
    height: 11.11vh;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.team .member .member-name {
    font-size: 1.25vw;
    font-weight: 500;
}

.team .member .member-role {
    font-size: .74vw;
    letter-spacing: .1em;
}

.scroll-buttons {
    position: fixed;
    top: 50%;
    right: 3.33vw;
    right: 35.5vw;
    transform: translateY(-50%);
    z-index: 1;
}

.scroll-buttons button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2vw;
    height: 2vw;
    background: none;
    border: none;
    padding: 0;
    color: #0A7E8C;
    border: 1px solid rgba(29, 171, 214, 0.23);
    margin: 20px 0;
    border-radius: 50%;
}

.scroll-buttons button[disabled] {
    color: #D7D3C8;
    border-color: #D7D3C8;
    pointer-events: none;
}

.scroll-buttons button .icon {
    width: .83vw;
    height: .83vw;
}

.overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 16.66vw;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.8);
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    transition: all .2s ease-out;
}

.overlay[opened] {
    visibility: visible;
    opacity: 1;
}

.modal {
    position: relative;
    width: 40vw;
    max-width: 90%;
    padding: 24px;
    background: #fff;
    box-shadow: 0 12px 36px rgba(101, 102, 98, 0.16);
}

.modal .modal-actions {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    padding: 24px;
    z-index: 2;
}

.modal .modal-content {
    position: relative;
    height: 100%;
    overflow: auto;
}

.director-card .director-details {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.director-card .director-image {
    margin: 0 16px 0 0;
    padding: 1.33vh 1.83vh 1.33vh 1vh;
}

.director-card .director-image img {
    display: block;
    width: 8.88vh;
    height: 8.88vh;
    object-fit: cover;
    border-radius: 50%;
}

.director-card .director-name {
    font-size: 32px;
    font-weight: bold;
    color: #0A7E8C;
}

.director-card .director-role {
    font-size: 18px;
    color:#0A7E8C;
}

@media screen and (max-width: 1023px) {
    .page {
        padding: 140px 32px 0;
        min-height: 100vh;
    }
    .page-background {
        display: none;
    }
    .page-title {
        display: block;
    }
    .page-title h2 {
        font: bold 56px/1 'Program';
        color: #0A7E8C;
        margin-bottom: 56px;
    }
    .team {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        overflow: unset;
        padding: 0;
    }
    .team:after {
        display: none;
    }
    .team .member {
        display: inline-block;
        width: 175px;
        float: none;
        margin: 0 0 48px;
    }
    .team .member .member-photo-container,
    .director-card .director-image {
        padding: 16px;
        margin-bottom: 16px;
    }
    .team .member .member-photo {
        width: 114px;
        height: 114px;
    }
    .team .member .member-name {
        font-size: 20px;
    }
    .team .member .member-role {
        font-size: 14px;
        margin-top: 8px;
    }
    .scroll-buttons {
        display: none;
    }
    .overlay {
        left: 0;
    }
    .modal {
        width: 480px;
        max-height: 90%;
    }
    .director-card .director-name {
        font-size: 24px;
    }
    .director-card .director-role {
        font-size: 14px;
    }
    .director-card .director-bio {
        font-size: 14px;
    }
    .director-card .director-image {
        margin-right: 12px;
        margin-bottom: 0;
        padding: 12px 14px 12px 10px;
    }
    .director-card .director-image img {
        width: 64px;
        height: 64px;
    }
}
</style>
